<template>
  <div>
    <div class="flex flex-col items-center justify-center">
      <div v-if="!isMeta">
        <div
          class="px-4 mx-8 text-secondary text-xl text-center font-bold mt-3"
        >
          {{ $t("pass-ready-for-download") }}
        </div>
        <download-buttons />
      </div>
      <div v-else class="text-center text-secondary mt-3">
        <div class="text-2xl">{{ $t("signup-completed") }}</div>
        <div class="mt-7 text-lg">
          {{ $t("download-pass-email") }}
        </div>
        <div class="mt-7 text-lg">{{ $t("thank-you") }}</div>
      </div>
    </div>
  </div>
  <c-loading-screen v-if="waitingNotificationsApproval" :show-spinner="false" />
</template>

<script>
import { mapStores, mapState } from "pinia";
import { authStore } from "@/stores/auth";
import { useNotification } from "naive-ui";
import CLoadingScreen from "../../../core-ui/src/components/CLoadingScreen.vue";
import DownloadButtons from "./DownloadButtons.vue";
import api from "@/api.js";

export default {
  name: "DownloadPage",
  data() {
    return {
      notificationCreator: null,
      loading: false,
      waitingNotificationsApproval: false,
    };
  },
  async mounted() {
    if (!this.customerId) {
      this.$router.replace({ name: "signupPage" });
    }
    let locationId =
      this.authStore.locationsBySlug[this.slugNameOrLocationId]?.id ||
      this.authStore.locationsById[this.slugNameOrLocationId]?.id;
    if (locationId) {
      await api.post(`/customer/${this.customerId}/detected`, {
        locationId,
      });
    }
    this.notificationCreator = useNotification();
  },
  computed: {
    ...mapStores(authStore),
    ...mapState(authStore, [
      "passId",
      "serviceWorkerRegistration",
      "organization",
      "locationsById",
    ]),
    isMeta() {
      return (
        window.navigator.userAgent.includes("FB") ||
        window.navigator.userAgent.includes("Instagram")
      );
    },
    customerId() {
      return this.authStore.currentCustomer?.id;
    },
    slugNameOrLocationId() {
      return this.$route.params.slugNameOrLocationId;
    },
  },
  components: {
    CLoadingScreen,
    DownloadButtons,
  },
};
</script>

<style scoped></style>
