<template>
  <div class="pb-20" v-if="gameParticipation">
    <div
      class="mb-6 flex flex-col items-center justify-center text-base text-center transition-all text-secondary"
      :class="{ 'fade-out': won, hidden: won }"
    >
      <div class="">{{ $t("scratch-explanation") }}:</div>
    </div>
    <div class="sc__wrapper rounded-full overflow-hidden">
      <div id="gameContainer" class="sc__container"></div>
    </div>
    <div
      class="mt-3 flex flex-col items-center justify-center font-semibold text-xl heartbeat transition-all text-secondary"
      :class="{ 'fade-out': won, hidden: won }"
    >
      <c-icon name="KeyboardArrowUpRound" size="2rem" />
      <div class="-mt-2">
        {{ $t("scratch-here") }}
      </div>
    </div>
    <div
      id="wonSection"
      :class="{ 'fade-in': won, hidden: !won }"
      class="text-secondary flex flex-col items-center transition-all text-xl text-center mt-12 pb-12"
    >
      <div v-if="gameParticipation.couponPromotionId">
        <div>
          {{ $t("congratulations") }}
        </div>
        <div class="font-semibold mt-1">
          {{
            $t("coupon-won-message", {
              couponPromotionName:
                promotionsById?.[gameParticipation?.couponPromotionId]?.name,
            })
          }}
        </div>
        <div class="text-base mt-10">
          {{
            $t("download-pass-good-message", {
              organizationName: organization.name,
            })
          }}:
        </div>
      </div>
      <div v-else>
        <div>
          {{ $t("too-bad") }}
        </div>
        <div class="text-base mt-10">
          {{
            $t("download-pass-bad-message", {
              organizationName: organization.name,
            })
          }}
        </div>
      </div>
      <download-buttons />
    </div>
  </div>
</template>

<script>
import { ScratchCard, SCRATCH_TYPE } from "scratchcard-js";
import { mapState } from "pinia";
import { authStore } from "../stores/auth";
import CIcon from "../../../core-ui/src/components/CIcon.vue";
import DownloadButtons from "./DownloadButtons.vue";
import api from "../api";
import { nextTick } from "vue";

function scrollToElement(element, duration = 2000) {
  const start = window.scrollY;
  const end = element.getBoundingClientRect().top + window.scrollY;
  const startTime = performance.now();

  function scrollStep(currentTime) {
    const elapsed = currentTime - startTime;
    const progress = Math.min(elapsed / duration, 1);
    window.scrollTo(0, start + (end - start) * progress);

    if (progress < 1) {
      requestAnimationFrame(scrollStep);
    }
  }

  requestAnimationFrame(scrollStep);
}

export default {
  name: "GamePage",
  data() {
    return {
      won: false,
      promotions: [],
      gameParticipation: null,
    };
  },
  async mounted() {
    if (!this.customerId) {
      this.$router.replace({ name: "signupPage" });
      return;
    }
    this.promotions = (
      await api.get(`/organization/${this.branding.organizationId}/promotions`)
    ).data.promotions;
    this.gameParticipation = (
      await api.get(`/gameParticipation/${this.gameParticipationId}`)
    ).data.gameParticipation;
    nextTick(() => {
      if (
        this.gameParticipation.playedTime &&
        this.gameParticipation.id !== "1234" &&
        this.organization.id !== "b7318228-9de1-424e-9258-3fcb09e126f3" // demo organization
      ) {
        this.won = true;
      }
      const gameContainer = document.getElementById("gameContainer");
      const scratchCard = new ScratchCard(gameContainer, this.scratchOptions);
      scratchCard.canvas.addEventListener("scratch.move", async () => {
        let percent = scratchCard.getPercent();
        if (percent > this.percentToFinish) {
          this.won = true;
        }
      });
      scratchCard.init();
    });
  },
  methods: {
    async handleWon() {
      if (this.gameParticipation.couponPromotionId) {
        this.showConfetti(1);
        this.showConfetti(-1);
      }
      let wonSection = document.getElementById("wonSection");
      setTimeout(() => {
        // wonSection.scrollIntoView({ behavior: "smooth", block: "center" });
        scrollToElement(wonSection, 2000);
      }, 100);
      await api.post(`/gameParticipation/${this.gameParticipationId}/played`);
    },
    showConfetti(side = 1) {
      function random(max) {
        return Math.random() * (max - 0) + 0;
      }

      var c = document.createDocumentFragment();
      for (var i = 0; i < 200; i++) {
        var styles =
          "transform: translate3d(" +
          random(1.2) * random(700 * side) +
          "px, " +
          random(1.2) * random(-1000) +
          "px, 0) rotate(" +
          random(360) +
          "deg);\
                  background: hsla(" +
          random(360) +
          ",100%,50%,1);\
                  animation: bang 4000ms ease-out forwards;\
                  opacity: 0";

        var e = document.createElement("i");
        e.style.cssText = styles.toString();
        c.appendChild(e);
      }
      let confettiOrigin = document.createElement("div");
      confettiOrigin.classList.add("confettiOrigin");
      confettiOrigin.classList.add("fixed");
      side === 1
        ? confettiOrigin.classList.add("left-0")
        : confettiOrigin.classList.add("right-0");
      confettiOrigin.classList.add("bottom-0");
      confettiOrigin.classList.add("w-2");
      document.body.appendChild(confettiOrigin);
      confettiOrigin.appendChild(c);
    },
  },
  computed: {
    ...mapState(authStore, ["branding", "organization", "customerId"]),
    gameParticipationId() {
      return this.$route.params.gameParticipationId;
    },
    promotionsById() {
      return this.promotions.reduce((acc, promotion) => {
        acc[promotion.id] = promotion;
        return acc;
      }, {});
    },
    percentToFinish() {
      return 30;
    },
    scratchBackColor() {
      return this.branding.accentColor;
    },
    scratchOptions() {
      const gameContainer = document.getElementById("gameContainer");
      return {
        scratchType: SCRATCH_TYPE.LINE,
        containerWidth: gameContainer?.offsetWidth,
        containerHeight: 250,
        imageForwardSrc: this.forwardImageSrc,
        imageBackgroundSrc:
          "https://res.cloudinary.com/comebackapp/image/upload/f_auto,q_auto/h_384,w_384,c_fit/v1664623844/qy4cvwckohlhrjzilowr",
        htmlBackground: `<div style='background-color: ${this.$utils.darken(
          this.scratchBackColor,
          30
        )}' class='h-[250px] flex flex-row items-center justify-center'><div class='text-xl text-contrast uppercase font-bold'>${
          this.gameParticipation.couponPromotionId
            ? this.promotionsById[this.gameParticipation.couponPromotionId]
                ?.name
            : this.$t("no-prize")
        }</div></div>`,
        clearZoneRadius: 20,
        nPoints: 0,
        pointSize: 0,
        enabledPercentUpdate: true,
        percentToFinish: this.percentToFinish, // enabledPercentUpdate must to be true
        callback: function () {},
      };
    },
    forwardImageSrc() {
      let rewardStampImageId = this.branding.rewardStampImageId;
      let backColor = this.scratchBackColor.replace("#", "");
      let iconColor = this.$utils.colorIsDark(this.scratchBackColor)
        ? "ffffff"
        : "000000";
      return `https://res.cloudinary.com/comebackapp/image/upload/f_auto,q_auto/h_384,w_384,c_fit,co_rgb:${backColor},e_colorize/l_${rewardStampImageId},h_200,w_200,co_rgb:${iconColor},e_colorize/fl_layer_apply/one_pixel`;
    },
  },
  components: {
    CIcon,
    DownloadButtons,
  },
  watch: {
    won(val) {
      if (val) {
        this.handleWon();
      }
    },
  },
};
</script>

<style lang="scss">
.sc__inner {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.sc__wrapper {
  display: block;
  width: 100%;
  height: 250px;
  max-width: 250px;
  margin: 0 auto;
  border: 5px solid white;
}

.sc__container {
  position: relative;
  overflow: hidden;
  height: 300px;
  max-width: 300px;
}

.sc__container > img {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
}

.sc__container canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
}

@keyframes bang {
  from {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

.confettiOrigin {
  width: 240px;
  margin: 150px auto 0;
  text-align: center;
  padding: 10px 0;
  cursor: pointer;
  position: relative;
  span {
    color: #333;
    font-size: 0.9em;
  }
  i {
    position: absolute;
    display: block;
    left: 50%;
    top: 0;
    width: 5px;
    height: 15px;
    background: red;
    opacity: 0;
  }
}

.heartbeat {
  -webkit-animation: heartbeat 1s ease-in-out infinite both;
  animation: heartbeat 1s ease-in-out infinite both;
}

@-webkit-keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
    transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
    transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}
@keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
    transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
    transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}

.fade-out {
  -webkit-animation: fade-out 1.2s ease-out both;
  animation: fade-out 1.2s ease-out both;
}

@-webkit-keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    display: none;
  }
}
@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    display: none;
  }
}

.fade-in {
  -webkit-animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
