import { createRouter, createWebHistory } from "vue-router";
import SignupPage from "@/components/SignupPage.vue";
import DownloadPage from "@/components/DownloadPage.vue";
import PolicyPage from "@/components/PolicyPage.vue";
import PageShell from "@/components/PageShell.vue";
import RatingPage from "@/components/RatingPage.vue";
import GamePage from "@/components/GamePage.vue";
import RatingRedirect from "@/components/RatingRedirect.vue";
import AddPointsPage from "@/components/AddPointsPage.vue";
import RecoveryPage from "@/components/RecoveryPage.vue";
import NotFoundPage from "@/components/NotFoundPage.vue";

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: "/:shortenedSignupPageId/ratingRedirect",
      name: "ratingRedirect",
      component: RatingRedirect,
    },
    {
      path: "/:shortenedSignupPageId/notFound",
      name: "notFound",
      component: NotFoundPage,
    },
    {
      path: "/",
      name: "pageShell",
      component: PageShell,
      children: [
        {
          path: "/:shortenedSignupPageId/rating",
          name: "ratingPage",
          component: RatingPage,
        },
        {
          path: "/:shortenedSignupPageId/game/:gameParticipationId",
          name: "gamePage",
          component: GamePage,
        },
        {
          path: "/:shortenedSignupPageId/:slugNameOrLocationId?/download",
          name: "downloadPage",
          component: DownloadPage,
        },
        {
          path: "/:shortenedSignupPageId/:slugNameOrLocationId?/addPoints",
          name: "addPointsPage",
          component: AddPointsPage,
        },
        {
          path: "/:shortenedSignupPageId/policy",
          name: "policy",
          component: PolicyPage,
        },
        {
          path: "/:shortenedSignupPageId/recovery",
          name: "recoveryPage",
          component: RecoveryPage,
        },
        {
          path: "/:shortenedSignupPageId/:slugNameOrLocationId?",
          name: "signupPage",
          component: SignupPage,
        },
      ],
    },
  ],
});

export default router;
