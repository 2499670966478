<template>
  <div class="w-full border-gray-300 border rounded-sm">
    <span class="arrow" :class="{ active: popShow }"></span>
    <input
      id="input1"
      type="text"
      class="w-full py-[0.3rem] border-gray-400 px-3 placeholder:text-[#C2C2C2]"
      v-model="selectNilai"
      @focus="showPanel"
      :placeholder="placeholder"
    />
    <input
      type="hidden"
      readonly="readonly"
      :class="inputClass"
      :style="inputStyle"
      :placeholder="placeholder"
      v-model="selectValue"
      @focus="showPanel"
    />
    <div
      class="vue-date-select-container"
      :class="{ 'vm-date-show': popShow }"
      @mousemove="handleTouch($event)"
      @mouseup="handleTouch($event)"
      @touchend="clearHover"
      @click="hidePanel"
    >
      <div
        class="vm-dialog-content s"
        @click.stop
        :style="{ 'margin-top': parseInt((windowHeight - 260) * 0.4) + 'px' }"
      >
        <div class="vm-wheels">
          <div
            class="vm-wheel"
            v-for="(item, wheelIndex) in wheels"
            :key="wheelIndex"
            @touchstart="handleTouch($event, wheelIndex)"
            @touchmove="handleTouch($event, wheelIndex)"
            @touchend="handleTouch($event, wheelIndex)"
            @mouseup="handleTouch($event, wheelIndex)"
            @mousedown="handleTouch($event, wheelIndex)"
            @mousewheel="handleTouch($event, wheelIndex)"
            @DOMMouseScroll="handleTouch($event, wheelIndex)"
          >
            <div class="vm-line border-black"></div>
            <div
              class="vm-items-wrapper"
              :class="{ anim: item.anim }"
              :style="{
                transform: 'translate3d(0,' + item.translateY + 'px, 0)',
                'transition-duration': item.anim ? item.transitionTime : '0s'
              }"
            >
              <div
                v-for="(optionItem, itemIndex) in item.data"
                :key="itemIndex"
                class="vm-option"
                :class="wheelIndex == 1 ? 'bulan' : 'normal'"
                @click="handleSingleClick($event, wheelIndex, itemIndex)"
                @touchstart="hoverClass($event, wheelIndex, itemIndex)"
                @mousedown="hoverClass($event, wheelIndex, itemIndex)"
              >
                <span v-if="wheelIndex != 1">
                  {{ optionItem < 10 ? '0' + optionItem : optionItem }}
                </span>
                <span v-else>
                  {{ callBulan(wheelIndex, optionItem) }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="vm-btns">
          <div
            class="vm-btn"
            @click="getSelectData"
            @touchstart="hoverClass($event, -2, -2)"
            @mousedown="hoverClass($event, -2, -2)"
          >
            Confirmar
          </div>
        </div> -->

        <div class="flex flex-row items-center justify-center py-8">
          <n-button
            type="primary"
            size="large"
            class="uppercase cursor-pointer pointer-events-auto"
            @click="getSelectData()"
          >
            <div>
              <c-icon name="CheckRound" />
            </div>
          </n-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CIcon from './CIcon.vue'

export default {
  name: 'CDatePicker',
  components: {
    CIcon
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    min: {
      type: String,
      default: ''
    },
    max: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    inputClass: {
      type: [String, Object, Array],
      default: ''
    },
    inputStyle: {
      type: [String, Object, Array],
      default: ''
    },
    themeColor: {
      type: String,
      default: '#03a9f4'
    },
    monthTranslations: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data: function () {
    return {
      popShow: false,
      selectValue: '',
      selectNilai: '',
      wheels: [
        {
          type: 'month',
          translateY: 0,
          anim: false,
          transitionTime: '700ms',
          data: []
        },
        {
          type: 'day',
          translateY: 0,
          anim: false,
          transitionTime: '700ms',
          data: []
        },
        {
          type: 'year',
          translateY: 0,
          anim: false,
          transitionTime: '700ms',
          data: []
        }
      ],
      startY: 0,
      moveY: 0,
      oldMoveY: 0,
      moveEndY: 0,
      offsetDistance: 0,
      offset: 0,
      oversizeBorder: 0,
      startTime: 0,
      liHeight: 40,
      minDate: '',
      maxDate: '',
      initDate: '',
      clickFlag: false,
      activeClick: {
        wheelIndex: -100,
        itemIndex: -100
      },
      activeWheelIndex: 0,
      windowHeight: 300
    }
  },
  computed: {
    hoverColor: function () {
      var hex = this.themeColor,
        opacity = 0.08,
        result = ''
      if (hex.replace(/\s+/g, '').length === 7) {
        result =
          'rgba(' +
          parseInt('0x' + hex.slice(1, 3)) +
          ',' +
          parseInt('0x' + hex.slice(3, 5)) +
          ',' +
          parseInt('0x' + hex.slice(5, 7)) +
          ',' +
          opacity +
          ')'
      } else {
        var rgb = hex.split('(')[1].split(')')[0].split(',')
        result =
          'rgba(' +
          rgb[0].trim() +
          ',' +
          rgb[1].trim() +
          ',' +
          rgb[2].trim() +
          ',' +
          opacity +
          ')'
      }
      return result || ''
    }
  },
  mounted: function () {
    this.initSetting()
    this.initOption()
    this.initListener()
  },
  methods: {
    initListener: function () {
      var _this = this
      window.addEventListener(
        'resize',
        function () {
          _this.windowHeight = 300
        },
        false
      )
    },
    initSetting: function () {
      this.initDate =
        this.value && this.checkIsFormatStr(this.value)
          ? this.value
          : this.getDateStr(new Date())
      this.maxDate =
        this.max && this.checkIsFormatStr(this.max)
          ? this.max
          : this.getDateStr(new Date(), 2)
      this.minDate =
        this.min && this.checkIsFormatStr(this.min)
          ? this.min
          : this.getDateStr(new Date(), -100)
    },
    initOption: function () {
      var maxDateObj = this.getDateStrObj(this.maxDate)
      var minDateObj = this.getDateStrObj(this.minDate)
      var initDateObj = this.getDateStrObj(this.initDate)

      for (var i = minDateObj.year; i <= maxDateObj.year; i++) {
        this.wheels[0].data.push(i)
      }
      for (var j = 1; j <= 12; j++) {
        this.wheels[1].data.push(j)
      }
      for (
        var k = 1;
        k <= this.calcDays(initDateObj.year, initDateObj.month);
        k++
      ) {
        this.wheels[2].data.push(k)
      }
      this.locateWheelByVal(this.initDate)
    },
    locateWheelByVal: function (dateString) {
      var minDateObj = this.getDateStrObj(this.minDate)
      var dateObj = this.getDateStrObj(dateString)
      this.wheels[0].translateY = this.getDistanceByIndex(
        dateObj.year - minDateObj.year
      )
      this.wheels[1].translateY = this.getDistanceByIndex(dateObj.month - 1)
      this.wheels[2].translateY = this.getDistanceByIndex(dateObj.day - 1)
    },
    descBulan: function (blnNum) {
      let returns = ''
      if (blnNum == '01' || blnNum == 1) {
        returns = this.monthTranslations[1] || 'Enero'
      } else if (blnNum == '02' || blnNum == 2) {
        returns = this.monthTranslations[2] || 'Febrero'
      } else if (blnNum == '03' || blnNum == 3) {
        returns = this.monthTranslations[3] || 'Marzo'
      } else if (blnNum == '04' || blnNum == 4) {
        returns = this.monthTranslations[4] || 'Abril'
      } else if (blnNum == '05' || blnNum == 5) {
        returns = this.monthTranslations[5] || 'Mayo'
      } else if (blnNum == '06' || blnNum == 6) {
        returns = this.monthTranslations[6] || 'Junio'
      } else if (blnNum == '07' || blnNum == 7) {
        returns = this.monthTranslations[7] || 'Julio'
      } else if (blnNum == '08' || blnNum == 8) {
        returns = this.monthTranslations[8] || 'Agosto'
      } else if (blnNum == '09' || blnNum == 9) {
        returns = this.monthTranslations[9] || 'Septiembre'
      } else if (blnNum == 10) {
        returns = this.monthTranslations[10] || 'Octubre'
      } else if (blnNum == 11) {
        returns = this.monthTranslations[11] || 'Noviembre'
      } else if (blnNum == 12) {
        returns = this.monthTranslations[12] || 'Diciembre'
      }
      return returns
    },
    callBulan: function (status, numbers) {
      let returns = ''
      if (status == 1) {
        returns = this.descBulan(numbers)
      }
      return returns
    },
    getDateStrObj: function (dateString, offsetYear, offsetMonth, offsetDay) {
      var tempArr = dateString.split('-')
      return {
        year: ~~tempArr[0],
        month: ~~tempArr[1],
        day: ~~tempArr[2]
      }
    },
    addPrefix: function (num) {
      return num < 10 ? '0' + num : num
    },
    getDateStr: function (dateObj, offsetYear, offsetMonth, offsetDay) {
      var tempArr = []
      tempArr.push(dateObj.getFullYear() + (offsetYear || 0))
      tempArr.push(this.addPrefix(dateObj.getMonth() + 1 + (offsetMonth || 0)))
      tempArr.push(this.addPrefix(dateObj.getDate() + (offsetDay || 0)))
      return tempArr.join('-')
    },
    checkIsFormatStr: function (dateString) {
      if (dateString && typeof dateString == 'string') {
        var tempArr = dateString.split('-')
        if (tempArr.length > 2) {
          var year = ~~tempArr[0],
            month = ~~tempArr[1],
            day = ~~tempArr[2]
          if (
            year > 0 &&
            year < 10000 &&
            month >= 1 &&
            month <= 12 &&
            day >= 1 &&
            day <= this.calcDays(year, month)
          ) {
            return true
          }
        }
      }
      return false
    },
    getDistanceByIndex: function (index) {
      return (2 - index) * this.liHeight
    },
    getIndexByDistance: function (translateY) {
      return parseInt(-translateY / this.liHeight) + 2
    },
    getWheelData: function (wheelIndex) {
      var dataIndex = this.getIndexByDistance(
        this.wheels[wheelIndex].translateY
      )
      dataIndex = dataIndex < 0 ? 0 : dataIndex
      dataIndex =
        dataIndex >= this.wheels[wheelIndex].data.length
          ? this.wheels[wheelIndex].data.length - 1
          : dataIndex
      return this.wheels[wheelIndex].data[dataIndex]
    },
    calcDays: function (year, month) {
      return new Date(year, month, 0).getDate()
    },
    fixPosition: function (distance) {
      return Math.round(distance / this.liHeight) * this.liHeight
    },
    checkIsOverBorder: function (curWheelObj) {
      var _this = this
      this.oversizeBorder = -(curWheelObj.data.length - 3) * this.liHeight
      if (curWheelObj.translateY > 2 * this.liHeight) {
        setTimeout(function () {
          curWheelObj.transitionTime = '700ms'
          curWheelObj.translateY = 2 * _this.liHeight
        }, 100)
      } else if (curWheelObj.translateY < this.oversizeBorder) {
        setTimeout(function () {
          curWheelObj.transitionTime = '700ms'
          curWheelObj.translateY = _this.oversizeBorder
        }, 100)
      }
    },
    updateDays: function () {
      var newMonthDaysNum = this.calcDays(
        this.getWheelData(0),
        this.getWheelData(1)
      )
      if (
        newMonthDaysNum > 0 &&
        this.wheels[2].data.length != newMonthDaysNum
      ) {
        var tempArr = []
        for (var k = 1; k <= newMonthDaysNum; k++) {
          tempArr.push(k)
        }
        this.wheels[2].data = tempArr
        this.checkIsOverBorder(this.wheels[2])
      }
    },
    handleTouch: function (e, index) {
      e = e || window.event
      var curWheelObj =
        typeof index == 'number'
          ? this.wheels[index]
          : this.wheels[this.activeWheelIndex]
      switch (e.type) {
        case 'touchstart':
        case 'mousedown':
          if (e.type == 'touchstart') {
            this.startY = e.touches[0].clientY
          } else {
            this.startY = e.clientY
            this.activeWheelIndex = index
            this.clickFlag = true
            e.preventDefault()
          }
          curWheelObj.anim = false
          this.oldMoveY = this.startY
          this.startTime = new Date().getTime()
          break

        case 'touchend':
        case 'mouseup':
          if (e.type == 'touchend') {
            this.moveEndY = e.changedTouches[0].clientY
          } else {
            this.moveEndY = e.clientY
            this.clickFlag = false
          }

          this.offsetDistance = this.moveEndY - this.startY
          curWheelObj.anim = true
          curWheelObj.translateY = this.fixPosition(
            curWheelObj.translateY + this.offset
          )
          var offsetTime = new Date().getTime() - this.startTime
          var scrollSpeed = this.offsetDistance / offsetTime
          var tempTime = Math.abs(parseInt(scrollSpeed * 1000))
          curWheelObj.transitionTime = '700ms'
          if (Math.abs(scrollSpeed) > 0.3) {
            curWheelObj.transitionTime =
              tempTime > 700 ? tempTime + 'ms' : '700ms'
            curWheelObj.translateY = this.fixPosition(
              curWheelObj.translateY + scrollSpeed * 250
            )
          }
          this.checkIsOverBorder(curWheelObj)
          this.clearHover()
          break

        case 'mousemove':
        case 'touchmove':
          e.preventDefault()
          if (e.type == 'mousemove' && !this.clickFlag) {
            return false
          }
          this.moveY = e.type == 'touchmove' ? e.touches[0].clientY : e.clientY
          this.offset = this.moveY - this.oldMoveY
          curWheelObj.translateY += this.offset
          this.oldMoveY = this.moveY
          break

        case 'mousewheel':
          curWheelObj.anim = true
          curWheelObj.translateY = this.fixPosition(
            curWheelObj.translateY + parseInt((e.wheelDelta || e.detail) * 0.3)
          )
          this.oversizeBorder = -(curWheelObj.data.length - 3) * this.liHeight
          this.checkIsOverBorder(curWheelObj)
          break
      }
      if (index <= 1) {
        this.updateDays()
      }
    },
    handleSingleClick: function (e, wheelIndex, itemIndex) {
      if (Math.abs(this.offsetDistance) < 30) {
        this.wheels[wheelIndex].translateY = this.getDistanceByIndex(itemIndex)
      }
    },
    hoverClass: function (e, wheelIndex, itemIndex) {
      this.activeClick.wheelIndex = wheelIndex
      this.activeClick.itemIndex = itemIndex
    },
    clearHover: function () {
      this.activeClick.wheelIndex = -100
      this.activeClick.itemIndex = -100
    },
    getSelectData: function () {
      var _this = this
      var tempArr = []
      this.wheels.forEach(function (item, wheelIndex) {
        tempArr.push(_this.addPrefix(_this.getWheelData(wheelIndex)))
      })
      this.selectValue = tempArr.join('-')
      var dates = this.selectValue.split('-')
      this.selectNilai =
        _this.descBulan(dates[1]) + ' ' + dates[2] + ', ' + dates[0]
      this.$emit('update:value', this.selectValue)
      this.hidePanel()
    },
    hidePanel: function () {
      this.clearHover()
      this.popShow = false
      if (this.selectValue) {
        this.locateWheelByVal(this.selectValue)
      }
    },
    showPanel: function () {
      this.clearHover()
      let input = document.getElementById('input1')
      input.blur()
      this.popShow = true
    }
  }
}
</script>

<style lang="scss">
.container {
  text-align: center;
  input[type='text'] {
    padding: 13px 0px;
    border: 0;
    border: 1px solid #ccc;
    width: 350px;
    text-align: center;
    margin: 20px auto;
    font-size: 15px;
    color: #666;
  }
  ::placeholder {
    font-size: 15px;
    color: #ececf3;
  }
}
.vue-date-select-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99998;
  font-weight: 400;
  background: rgba(0, 0, 0, 0.4);
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  user-select: none;
  box-sizing: border-box;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s;
}
.vue-date-select-container * {
  box-sizing: inherit;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}
.vue-date-select-container .vm-dialog-content {
  z-index: 10;
  width: 80%;
  margin: 0px auto;
  min-width: 280px;
  max-width: 550px;
  background: #ffffff;
  padding: 10px 5px 0px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  opacity: 0;
}
.vue-date-select-container .vm-dialog-content.gender {
  ul {
    padding: 20px 10px 25px 10px;
    margin: 0;
    li {
      list-style: none;
      padding: 10px;
    }
    li:active {
      background: #ededed;
    }
  }
}
.vue-date-select-container .vm-dialog-content.s {
  pointer-events: none;
}
.vue-date-select-container .vm-dialog-content .vm-wheels {
  display: flex;
  position: relative;
}
.vue-date-select-container .vm-dialog-content .vm-wheels::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 100px;
  z-index: 2;
  background: -moz-linear-gradient(
    top,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=0 ); /* IE6-9 */
}
.vue-date-select-container .vm-dialog-content .vm-wheels::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100px;
  z-index: 3;
  background: -moz-linear-gradient(
    top,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
}
.vue-date-select-container .vm-dialog-content .vm-wheels .vm-wheel {
  position: relative;
  z-index: 2;
  width: 33.33%;
  height: 200px;
  text-align: center;
  margin: 0px;
  overflow: hidden;
  pointer-events: auto;
  touch-action: none;
}
.vue-date-select-container .vm-dialog-content .vm-wheels .vm-wheel .vm-line {
  position: absolute;
  top: 50%;
  left: 0px;
  height: 40px;
  width: 100%;
  margin-top: -21px;
  border-top: 1px solid;
  border-bottom: 1px solid;
  pointer-events: none;
}
.vue-date-select-container
  .vm-dialog-content
  .vm-wheels
  .vm-wheel
  .vm-items-wrapper {
  overflow: hidden;
  pointer-events: auto;
  cursor: pointer;
}
.vue-date-select-container .vm-dialog-content .vm-wheels .vm-wheel .anim {
  /*transition: transform 700ms cubic-bezier(0.19, 1, 0.22, 1) 0s;*/
}
.vue-date-select-container .vm-dialog-content .vm-option {
  position: relative;
  height: 40px;
  line-height: 40px;
  font-size: 20px;
  color: #454545;
}
.vue-date-select-container .vm-dialog-content .vm-btns {
  text-align: center;
  padding: 20px;
}
.vue-date-select-container .vm-dialog-content .vm-btns .vm-btn {
  position: relative;
  display: inline-block;
  color: #fff;
  font-size: 14px;
  background: #26ade4;
  cursor: pointer;
  border-radius: 5px;
  padding: 7px 35px;
  pointer-events: auto;
}
.vue-date-select-container .hover-color-bg {
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  opacity: 0;
}
.vue-date-select-container.vm-date-show {
  opacity: 1;
  background: rgba(0, 0, 0, 0.7);
  visibility: visible;
}
.vue-date-select-container.vm-date-show .vm-dialog-content {
  transform: scale(1);
  opacity: 1;
  top: 20%;
  border-radius: 5px;
  position: relative;
}
</style>
