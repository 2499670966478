<template>
  <n-icon
    @click="$emit('click', $event)"
    :size="size"
    :component="importedIcon"
    :class="{
      'opacity-50 pointer-events-none': disabled
    }"
  />
</template>

<script>
import {
  HistoryFilled,
  QrCodeScannerFilled,
  CloseFilled,
  PersonFilled,
  PersonAddFilled,
  ArrowBackRound,
  KeyboardArrowLeftRound,
  KeyboardArrowUpRound,
  ArrowForwardIosRound,
  ArrowBackIosRound,
  CardGiftcardRound,
  MinusRound,
  PlusRound,
  SearchOffFilled,
  PersonOffRound,
  HomeRound,
  SettingsRound,
  SupervisorAccountRound,
  ColorLensRound,
  DiscountRound,
  LibraryBooksRound,
  LocationCityRound,
  AccountCircleRound,
  CoffeeFilled,
  EditRound,
  CheckRound,
  LanguageRound,
  AppleRound,
  AndroidRound,
  MessageRound,
  InfoOutlined,
  DeleteFilled,
  BackspaceOutlined,
  StarFilled,
  StarBorderFilled,
  OpenInNewFilled,
  SearchFilled,
  KeyboardArrowDownRound,
  KeyboardArrowDownOutlined,
  EmailOutlined,
  SmsOutlined,
  NotificationsNoneOutlined,
  FileUploadOutlined,
  AccountTreeRound,
  KeyboardDoubleArrowRightRound,
  EmailFilled,
  CampaignRound,
  CakeRound,
  StarRound,
  SnoozeRound,
  PersonAddAlt1Round,
  InboxTwotone,
  AddLocationAltRound,
  DisplaySettingsRound,
  KeyboardAltFilled,
  FileDownloadOutlined,
  TrendingUpRound,
  WhatsappFilled,
  QrCode2Filled,
  ContentCopyOutlined,
  FilterAltOutlined,
  FilterAltOffOutlined,
  HourglassTopFilled,
  CompareArrowsRound,
  ShoppingBagOutlined,
  DocumentScannerRound,
  EventFilled,
  GroupAddRound,
  PowerSettingsNewRound,
  ManageAccountsFilled,
  PercentRound,
  SendRound,
  PersonAddRound,
  WebRound,
  ShareRound,
  BlockRound,
  Md123Round,
  ExposurePlus1Round,
  LocalPhoneOutlined,
  CalendarMonthRound,
  AccountBalanceWalletOutlined,
  AutorenewOutlined,
  DiscountOutlined,
  CakeOutlined,
  InstallMobileOutlined,
  CampaignOutlined,
  SourceOutlined,
  SettingsOutlined,
  AmpStoriesOutlined,
  BoltRound,
  StarBorderRound,
  VideogameAssetRound,
  EmojiPeopleRound,
  AutoAwesomeRound
} from '@vicons/material'

const iconMap = {
  HistoryFilled,
  QrCodeScannerFilled,
  CloseFilled,
  PersonFilled,
  PersonAddFilled,
  ArrowBackRound,
  ArrowForwardIosRound,
  ArrowBackIosRound,
  KeyboardArrowLeftRound,
  CardGiftcardRound,
  MinusRound,
  PlusRound,
  SearchOffFilled,
  SearchFilled,
  HomeRound,
  SettingsRound,
  SupervisorAccountRound,
  ColorLensRound,
  DiscountRound,
  LibraryBooksRound,
  LocationCityRound,
  AccountCircleRound,
  CoffeeFilled,
  EditRound,
  CheckRound,
  LanguageRound,
  AppleRound,
  AndroidRound,
  MessageRound,
  InfoOutlined,
  DeleteFilled,
  BackspaceOutlined,
  StarFilled,
  StarBorderFilled,
  OpenInNewFilled,
  KeyboardArrowDownRound,
  KeyboardArrowUpRound,
  KeyboardArrowDownOutlined,
  EmailOutlined,
  SmsOutlined,
  NotificationsNoneOutlined,
  FileUploadOutlined,
  AccountTreeRound,
  KeyboardDoubleArrowRightRound,
  EmailFilled,
  CampaignRound,
  CakeRound,
  StarRound,
  StarBorderRound,
  SnoozeRound,
  PersonAddAlt1Round,
  PersonOffRound,
  InboxTwotone,
  AddLocationAltRound,
  DisplaySettingsRound,
  KeyboardAltFilled,
  FileDownloadOutlined,
  TrendingUpRound,
  WhatsappFilled,
  QrCode2Filled,
  ContentCopyOutlined,
  FilterAltOutlined,
  FilterAltOffOutlined,
  HourglassTopFilled,
  CompareArrowsRound,
  ShoppingBagOutlined,
  DocumentScannerRound,
  EventFilled,
  GroupAddRound,
  PowerSettingsNewRound,
  ManageAccountsFilled,
  PercentRound,
  SendRound,
  PersonAddRound,
  WebRound,
  ShareRound,
  BlockRound,
  Md123Round,
  ExposurePlus1Round,
  LocalPhoneOutlined,
  CalendarMonthRound,
  AccountBalanceWalletOutlined,
  AutorenewOutlined,
  DiscountOutlined,
  CakeOutlined,
  InstallMobileOutlined,
  CampaignOutlined,
  SourceOutlined,
  SettingsOutlined,
  AmpStoriesOutlined,
  BoltRound,
  VideogameAssetRound,
  EmojiPeopleRound,
  AutoAwesomeRound
}

export default {
  name: 'CIcon',
  props: {
    small: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: '1.5rem'
    },
    name: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    originalColor: {
      type: Boolean,
      default: false
    },
    svgType: {
      type: String,
      default: 'fill',
      validator: function (value) {
        // The value must match one of these strings
        return ['fill', 'stroke'].indexOf(value) !== -1
      }
    }
  },
  emits: ['click'],
  computed: {
    importedIcon() {
      return iconMap[this.name]
    }
  }
}
</script>

<style>
.current-color-stroke path {
  stroke: currentColor;
}
.current-color-fill path {
  fill: currentColor;
}
svg {
  display: block;
  width: 100%;
  height: 100%;
}
</style>
